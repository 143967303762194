<template>
  <a-row class="content-fix" type="flex">
    <spinner v-if="loading" />
    <a-col v-else class="height100 flex-1">
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab == 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />{{ $t("ingradient") }}
        </button>

        <button
          v-show="form.is_static == true"
          class="tab-btn"
          :class="{ active: tab == 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />{{ $t("Img") }}
        </button>
        <button
          v-show="form.is_static == true"
          class="tab-btn"
          :class="{ active: tab == 'document' }"
          @click="tab = 'document'"
        >
          <a-icon type="file" />{{ $t("Documents") }}
        </button>
      </a-row>
      <div v-show="tab == 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
          <a-col :span="12">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <!-- <a-button v-if="langTab == 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab == "uz" ? "кирилицу" : "латиницу" }}
              </a-button> -->
            </a-row>
          </a-col>
        </a-row>
        <!-- route name -->
        <a-row
          :class="{ 'form-group--error': titlesCheck }"
          align="middle"
          class="flex-baseline input-form__group"
        >
          <a-col :span="4" class="form_title">
            {{ $t("MenuTitle") }}
          </a-col>
          <a-col :span="12">
            <a-input
              v-model="form.name[langTab]"
              :placeholder="$t('MenuTitlePlace')"
              class="form_title-input"
              @change="slugCompute($event, langTab, 'menus', form.name)"
            />
            <div v-if="!$v.form.name[langTab].required" class="error-text">
              {{ $t("EnterTheTitle") }}
            </div>
            <!-- <div v-if="!$v.form.title.ru.required" class="error-text">
              Введите название на русском.
            </div> -->
          </a-col>
        </a-row>
        <!-- slug -->
        <a-row align="middle" class="flex-baseline" v-show="false">
          <a-col :span="4" class="form_title"> {{ $t("Alias") }}: </a-col>
          <a-col :span="12" class="sm-mb">
            <a-row align="middle" type="flex">
              <span class="mr-1 link" @click="copySlug">
                <a-icon :style="{ fontSize: '22px' }" type="copy" />
              </span>

              <span class="flex-1">
                <a-input
                  id="slug-input"
                  :value="form.slug"
                  :placeholder="$t('EnterAlias')"
                  @input="onSlugChange"
                />
              </span>
            </a-row>
            <div v-if="!form.slug" class="error-text">{{ $t("EnterAlias") }}:</div>
            <div v-if="errors.slug" class="z-error-text">{{ $t("NoAlias") }}:</div>
          </a-col>
        </a-row>
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title">
            {{ $t("MenuGroup") }}
          </a-col>
          <a-col :span="12">
            <a-input v-model="form.category" :placeholder="$t('MenuGroupPlace')" />
          </a-col>
        </a-row>
        <!-- position -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title"> URL: </a-col>
          <a-col :span="12">
            <a-input v-model="form.url" :placeholder="$t('EnterUrlLink')" />
          </a-col>
        </a-row>
        <!-- menu list to pick parent menu -->
        <a-row style="margin-bottom: 15px">
          <a-col :span="4" class="form_title">
            {{ $t("MenuParentName") }}
          </a-col>
          <a-col :span="12">
            <a-select
              v-model="form.parent_id"
              :filter-option="filterOption"
              option-filter-prop="children"
              :placeholder="$t('MenuParentName')"
              show-search
              style="width: 100%"
            >
              <a-select-option v-if="form.id" :value="0">
                {{ $t("SelectMenu") }}
              </a-select-option>
              <a-select-option v-for="menu in menuList" :key="menu.id" :value="menu.id">
                {{ menu.name || "-" }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <!-- position -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title">
            {{ $t("MenuPosition") }}
          </a-col>
          <a-col :span="12">
            <a-input-number v-model="form.position" :placeholder="$t('positionPlac')" />
            <div v-if="!form.position" class="error-text">{{ $t("positionPlac") }}</div>
          </a-col>
        </a-row>
        <!-- status toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t("IsStatic") }}: </a-col>
          <a-switch v-model="form.is_static" />
        </a-row>
        <!-- active toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t("Active") }}: </a-col>
          <a-switch v-model="form.status" />
        </a-row>
        <!-- has_category toggle -->
        <a-row style="margin-top: 25px">
          <a-col :span="4" class="form_title"> {{ $t("HasCategory") }}: </a-col>
          <a-switch v-model="form.has_category" />
        </a-row>
        <a-row v-if="form.is_static" style="margin-top: 25px" type="flex">
          <a-col :span="4" class="form_title"> {{ $t("Content") }}: </a-col>
          <a-col v-show="langTab == 'oz'" :span="18">
            <!-- :key="langtab" -->
            <editor
              :value="form.content && form.content.oz"
              :initial-value="form.content && form.content.oz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.oz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'uz'" :span="18">
            <!-- :key="langtab" -->
            <editor
              :value="form.content && form.content.uz"
              :initial-value="form.content && form.content.uz"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.uz = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'ru'" :span="18">
            <!-- :key="langtab" -->
            <editor
              :value="form.content && form.content.ru"
              :initial-value="form.content && form.content.ru"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.ru = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
          <a-col v-show="langTab == 'en'" :span="18">
            <!-- :key="langtab" -->
            <editor
              :value="form.content && form.content.en"
              :initial-value="form.content && form.content.en"
              :init="{
                ...tinymce.default.props,
                menubar: 'file edit view insert format tools table tc help',
                setup: (editor) => {
                  editor.ui.registry.addButton('spoiler-btn', {
                    icon: 'comment-add',
                    tooltip: 'Спойлер',
                    onAction: () => {
                      spoiler.modalVisibility = editor;
                      spoilerHandler();
                    },
                  });
                  editor.ui.registry.addButton('storage-btn', {
                    icon: 'non-breaking',
                    tooltip: $t('ImageStorages'),
                    onAction: () => {
                      openStoreModal();
                      editorInstance = editor;
                    },
                  });
                },
              }"
              @input="form.content.en = editorChangeHandler(arguments[0])"
            />
            <div v-if="isContentNull" class="error-text">
              {{ $t("EditorErrTitle") }}
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- store image modal -->
      <a-modal
        :visible="modalVisible"
        :title="$t('ImageStorages')"
        :closable="false"
        style="max-height: 500px; max-width: 900px"
        width="900px"
      >
        <imageStorage @pasteHandler="editorInstance.insertContent(arguments[0])" />
        <template slot="footer">
          <a-button key="back" @click="modalVisible = false"> {{ $t('Close') }} </a-button>
        </template>
      </a-modal>
      <!-- photo tab -->
      <div v-show="tab == 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col
            :span="4"
            style="padding-top: 4px; text-align: right; padding-right: 8px"
          >
            {{ $t("TablePhotos") }}
          </a-col>
          <a-col :span="12">
            <image-upload
              :list="photoList"
              accept="image/jpeg, image/png"
              :with-cropper="true"
              has-method
              :notActiveImg="true"
              @change="handleImages"
              @drag-update="updateFilesPosition"
              @delete="deleteImage"
            />
          </a-col>
        </a-row>
      </div>
      <!-- document tab  -->
      <div v-show="tab == 'document' && form.is_static == true">
        <h4 v-if="pending">Идет загрузка...</h4>
        <span v-show="!pending">
          <a-row style="margin-bottom: 15px; margin-top: 15px">
            <a-col :span="4" class="form_title">{{ $t("documentFiles") }}:</a-col>
            <a-col :span="12">
              <a-button @click="$refs.documentRef.click()">
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handeDocumentInput"
                />
                <a-icon type="upload" />{{ $t("FileSelect") }}
              </a-button>
              <template v-if="document_files_array != 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />
                <a-row style="border-bottom: 1px solid #ddd; padding-bottom: 15px" />
              </template>
            </a-col>
          </a-row>
        </span>
      </div>
      <a-row
        v-if="tab == 'content'"
        class="form-buttons__action"
        type="flex"
        style="margin-top: 20px"
      >
        <a-col :span="4" class="form_title" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />{{ $t("Cancel") }}
          </a-button>
          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitMenu(form.id ? (saveback = false) : (saveback = true))"
          >
            <a-icon type="check" />
            {{ form.id ? $t("Save") : $t("Add") }}
          </a-button>
          <a-button
            v-if="form.id"
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitMenu((saveback = true))"
          >
            <a-icon type="check" />
            {{ $t("SaveAndExit") }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>
    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>
<script>
import spoiler from "@/mixins/spoiler.js";
import { required } from "vuelidate/lib/validators";
import cloneDeep from "lodash/cloneDeep";
import InputFileItem from "@/components/utils/input-file-item";
const def = () => ({
  position: 1,
  url: "",
  name: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  content: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
  },
  children: null,
  category: "",
  parent_id: null,
  status: false,
  is_static: false,
  has_category: false,
  slug: "",
});
export default {
  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    imageStorage: () => import("@/components/imageStorage/imageStorage"),
    editor: () => import("@tinymce/tinymce-vue"),
    spoilerModal: () => import("@/components/directory/modal-components/modal-spoiler"),
    InputFileItem,
  },
  mixins: [spoiler],
  data() {
    return {
      langTab: $langPrefix,
      loading: false,
      pending: false,
      form: { ...def },
      modalVisible: false,
      document_files_array: [],
      menuList: [],
      images: [],
      tab: "content",
      errors: {
        slug: "",
      },
    };
  },
  computed: {
    titlesCheck() {
      return !!(
        this.$v.form.name.oz.$error &&
        this.$v.form.name.uz.$error &&
        this.$v.form.name.ru.$error
      );
    },
    isContentNull() {
      return !!(
        this.form.content &&
        !this.form.content.uz &&
        !this.form.content.oz &&
        !this.form.content.ru
      );
    },
    photoList() {
      return this.form && this.form.images;
    },
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
    form: {
      handler() {
        if (!this.form.id && this.form.parent_id && this.$route.name != "menu-detail") {
          const f = this.menuList.findIndex((item) => item.id == this.form.parent_id);
          if (f != -1 && this.menuList[f].children && this.menuList[f].children.length) {
            const childrenMenuLen = this.menuList[f].children.length;
            this.form.position = childrenMenuLen + 1;
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.toDef();
  },
  beforeDestroy() {
    this.toDef();
  },
  validations: {
    form: {
      name: {
        uz: {
          required,
        },
        oz: {
          required,
        },
        ru: {
          required,
        },
        en: {
          required,
        },
        // qr: {
        //   required
        // }
      },
    },
  },
  mounted() {
    this.$on("update:slug", this.onSlugChange);
  },
  methods: {
    onSlugChange(e) {
      const text = (e.target.value || "")
        .replace(/( )/gi, "-")
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\\-]/gi, "");

      this.slugCheck({ table: "menus", slug: text });

      this.form.slug = text;
    },
    setSlugRes(arg) {
      this.errors.slug = arg ? "" : this.$t("NoAlias");
    },
    copySlug() {
      this.copyStringToClipboard(`/${this.$options.name}/${this.form.slug}`);
      this.$message.success("ЧПУ скопирован");
    },
    openStoreModal() {
      this.modalVisible = true;
    },
    toDef() {
      this.$set(this, "form", {
        ...def(),
      });
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab == "uz" ? "oz" : "uz",
          methodName = this.langTab == "uz" ? "toKirill" : "toLatin";

        if (this.form.name[from]) {
          this.form.name[to] = this[methodName](this.form.name[from]);
        }

        if (this.form.content[from]) {
          this.form.content[to] = this[methodName](this.form.content[from]);
        }
        // turn on translit target language_support
        // console.log(this.form.language_support, to, true)
        this.$set(this.form.language_support, to, true);
        this.slugCompute("", this.langTab, "menus", this.form.name);
      } catch (error) {
        this.$sentry.captureMessage(error);
      }
    },
    async fetchMenuList() {
      this.loading = true;
      try {
        const { data } = await this.$store.dispatch("menu/fetch", {
          parent: true,
        });
        this.$set(this, "menuList", {
          ...data,
        });
        console.log(this.menuList);
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
      this.loading = false;
    },
    async fetchData() {
      await this.fetchMenuList();
      if (!this.$route.params.id || isNaN(this.$route.params.id)) {
        return;
      }
      this.loading = true;
      try {
        const res = await this.$store.dispatch("menu/detail", this.$route.params.id);
        if (res) {
          console.log(res.data, "asdasdasdadsd");
          this.$set(this, "form", {
            ...this.form,
            ...res.data,
            position: +res.data.position,
            name: res.data.name || this.form.name,
          });
          this.form.docs &&
            this.form.docs.forEach((item) => {
              this.document_files_array = [
                ...this.document_files_array,
                {
                  binary_file: item,
                  uid: Math.random().toString().slice(2),
                },
              ];
            });
          if (!res.data.content) {
            this.form.content = {
              uz: "",
              oz: "",
              ru: "",
              en: "",
            };
          }
        }
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.$sentry.captureMessage(err);
      }
      this.loading = false;
    },
    async submitMenu(saveback) {
      // console.log(this.form)
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$message.error(`Заполните заголовок на всех языках`);
        return;
      }
      this.pending = true;
      try {
        let newData = cloneDeep(this.form);
        delete newData.docs;
        newData = {
          ...newData,
        };
        if (newData.is_static === false) {
          delete newData.content;
        }
        if (!newData.url) {
          delete newData.url;
        }
        if (newData.parent_id == null || newData.parent_id == "") {
          delete newData.parent_id;
        }
        if (newData.category == null) {
          delete newData.category;
        }
        let f = new FormData();
        Object.keys(newData).forEach((key) => {
          key != "images" && f.append(key, newData[key]);
        });
        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/menu/" + this.form.id
              : "/admin/menu/create",
          method: this.form && this.form.id ? "put" : "post",
          data: newData,
        };
        await this.$api[createOrUpdate.method](
          createOrUpdate.url,
          createOrUpdate.data
        ).then((res) => {
          let { data } = res;
          if (data.status) {
            if (saveback) {
              this.$router.push({
                name: "menu",
              });
            }
            this.fetchData();
            this.$message.success(`Меню успешно ${this.form.id ? "обновлен" : "создан"}`);
          }
        });
      } catch (e) {
        this.$message.error(`Ошибка при ${this.form.id ? "обновлении" : "создании"}`);
        this.pending = false;
      }
      this.pending = false;
    },
    updateFilesPosition(files) {
      let promises = [];
      let images_order = [];
      Array.from(files)
        .filter((item) => item.id && item.uzid !== this.main_image)
        .map((item, index) => {
          images_order = [...images_order, { id: item.id, position: index + 1 }];
        });
      promises.push(this.$api.put("/admin/menu-image/position", images_order));

      Promise.all(promises)
        .then(
          () => {},
          (err) => {
            console.log(err);
          }
        )
        .then(() => {
          this.$message.success("Позиции успешно сохранены");
        });
    },
    async handeDocumentInput(e) {
      if (this.form.id) {
        let menuID = this.$route.params.id;
        if (e.target.files && e.target.files != 0) {
          let formImage = {};
          let f = new FormData();
          Object.keys(formImage).forEach((k) => {
            f.append(k, formImage[k]);
          });
          Array.from(e.target.files).forEach((item) => {
            f.append("docs[]", item);
          });
          let res = await this.$api.post(`/admin/media/menu-doc/${menuID}`, f);
          if (res && res.data) {
            console.log(res.data);
            res.data.data.forEach((item) => {
              this.document_files_array = [
                ...this.document_files_array,
                {
                  binary_file: item,
                  uid: Math.random().toString().slice(2),
                },
              ];
            });
          }
        }
      } else {
        if (e.target.files && e.target.files != 0) {
          Array.from(e.target.files).forEach((item) => {
            this.document_files_array = [
              ...this.document_files_array,
              {
                binary_file: item,
                uid: Math.random().toString().slice(2),
              },
            ];
          });
          console.log(this.document_files_array);
        }
      }

      this.$refs.documentRef.value = null;
    },
    async deleteImage(val) {
      if (this.form.id) {
        await this.$api.delete("/admin/media/menu-image/" + val);
      } else {
        this.form.images = null;
      }
    },
    async handleImages(arg) {
      if (this.form.id) {
        this.fileList = arg;
        // let formImage = {
        //   menu: this.$route.params.id,
        // };
        let newImages = arg.filter((item) => item && !item.id);
        let f = new FormData();
        // Object.keys(formImage).forEach((k) => {
        //   f.append(k, formImage[k]);
        // });
        newImages.forEach((item) => {
          f.append(
            "images[]",
            item && item.fileTarget,
            item && item.fileTarget && item.fileTarget.name
          );
        });
        let res = await this.$api.post(`/admin/media/menu-image/${this.form.id}`, f);
        if (res && res.data && res.data.data) {
          this.images = res.data.data;
          this.fetchData();
        }
      } else {
        this.images.push(arg[0]);
      }
    },
    async deleteDocumentFileFromState(arg) {
      if (this.form.id) {
        try {
          const res = await this.$api.delete(
            "/admin/media/menu-doc/" + arg.binary_file.id
          );

          if (res) {
            const f = this.document_files_array.findIndex((item) => item.uid === arg.uid);

            if (f != -1) {
              this.document_files_array = [
                ...this.document_files_array.slice(0, f),
                ...this.document_files_array.slice(f + 1),
              ];
            }
          }
        } catch (err) {
          this.$sentry.captureMessage(err);
        }
      } else {
        this.document_files_array = this.document_files_array.filter(
          (el) => el.uid != arg.uid
        );
      }
    },
  },
};
</script>
